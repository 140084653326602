<template>
  <div class="events page nav-spcr">
    <div class="position-relative">
      <b-carousel id="carousel-fade" fade indicators >
        <b-carousel-slide caption="SYPHER Events" text="Bringing musical communities together in real spaces" :img-src='require("../assets/event_1.png")'><div class="overlay"></div></b-carousel-slide>
        <b-carousel-slide caption="SYPHER Efsdfvents" text="Bringing musical communities together in real spaces" :img-src='require("../assets/event_2.png")'><div class="overlay"></div></b-carousel-slide>

      </b-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: 'events'
}
  

</script>